import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import "./Header.css";



function Header() {


  return (
    <>
      {/* <div className="header-top">
        <div className="container-xl p-0">
          <div className="row justify-content-between">
            <div className="col-auto">
              <ul className="contact-list">
                <li> <i></i></li>
                <li> <i></i> </li>
              </ul>
            </div>
            <div className="col-auto text-end ms-auto">
              <ul className="social-list">
                <li> <Link to="https://www.facebook.com/ideamarch"><i className='bx bxl-facebook'></i></Link> </li>
                <li> <Link to="https://twitter.com/ideamarch"><i className='bx bxl-twitter'></i></Link> </li>
                <li> <Link to="https://in.linkedin.com/company/ideamarch"><i className='bx bxl-linkedin' ></i></Link> </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <div className="header-nav sticky-top" id="banner">
        <nav className="navbar navbar-expand-lg" id="nav-autospy">
          <div className="container-xl">
            <HashLink className="navbar-brand" to="/#home">
              <img src="ideamarch_250.png" alt="..." />
            </HashLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item"> <HashLink to="/#home" className="nav-link active" aria-current="page">Home</HashLink> </li>
                <li className="nav-item"> <HashLink to="/#about" className="nav-link" aria-current="page">About Us</HashLink> </li>
                <li className="nav-item"> <HashLink to="/#services" className="nav-link" aria-current="page" >Services</HashLink> </li>
                <li className="nav-item"> <HashLink to="/#products" className="nav-link" aria-current="page">Products</HashLink> </li>
                <li className="nav-item"> <Link to="/careers" className="nav-link" aria-current="page">Careers</Link> </li>
                <li className="nav-item"> <Link to="/contact" className="nav-link" aria-current="page">Contact</Link> </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
export default Header;