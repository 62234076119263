import React from 'react';
import './App.css';
//import Aboutus from './components/Aboutus';
import Careers from './components/Careers';
//import Home from './components/Home';
//import Services from './components/Services';
import { Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import Contactus from './components/Contactus';
//import Products from './components/Products';
import $ from "jquery";

function App() {
  return (
    <div>
      <Header />
        {/* <div data-bs-spy="scroll" data-bs-target="#nav-autospy" data-bs-smooth-scroll="true" data-bs-offset="0" tabindex="0"> */}
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/careers' element={<Careers />}></Route>
            <Route path='/contact' element={<Contactus />}></Route>
          </Routes>
        {/* </div> */}
      <Footer />
    </div>
  );
}

// $(document).on("scroll", function(){
//   if
//     ($(document).scrollTop() > 86){
//     $("#banner").addClass("shrink");
//   }
//   else
//   {
//     $("#banner").removeClass("shrink");
//   }
// });

$(document).on("scroll", function(){
  if
    ($(document).scrollTop() > 86){
    $(".scroll-to-top").addClass("active");
  }
  else
  {
    $(".scroll-to-top").removeClass("active");
  }
});

export default App;